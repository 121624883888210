<script lang="ts">
  export let direction: string = "front";
  export let content: string = "";
</script>

<style>
  .cube__face {
    position: absolute;
    width: var(--logo-size);
    height: var(--logo-size);

    display: grid;
    grid: 1fr 1fr 1fr / 1fr 1fr 1fr;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  .cube__face--front {
    transform: rotateY(0deg) translateZ(var(--logo-size-half));
  }
  .cube__face--right {
    transform: rotateY(90deg) translateZ(var(--logo-size-half));
  }
  .cube__face--back {
    transform: rotateY(180deg) translateZ(var(--logo-size-half));
  }
  .cube__face--left {
    transform: rotateY(-90deg) translateZ(var(--logo-size-half));
  }
  .cube__face--top {
    transform: rotateX(90deg) translateZ(var(--logo-size-half));
  }
  .cube__face--bottom {
    transform: rotateX(-90deg) translateZ(var(--logo-size-half));
  }

  .cell {
    background: linear-gradient(var(--logo-secondary), var(--logo-secondary)),
      url("assets/images/paper.jpg") 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.4);
    color: var(--logo-primary);
    font-weight: 600;

    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .cell--empty {
    background: linear-gradient(var(--logo-primary), var(--logo-primary)),
      url("assets/images/paper.jpg") 100% 100%;
  }
</style>

<svelte:options tag="cube-face" immutable={true} />
<div class="cube__face cube__face--{direction}">
  {#each content.split('') as txt}
    {#if txt === '_'}
      <span class="cell cell--empty" />
    {:else}<span class="cell">{txt}</span>{/if}
  {/each}
</div>
