import LogoCube from "./logo/logo-cube.svelte";
import NavMenu from "./nav-menu.svelte";
import LanguageSwitcher from "./language-switcher.svelte";
import { AboutSection, ContactSection, ClientsSection, StackSection, VideoSection, } from "./sections";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
export default {
    LogoCube,
    NavMenu,
    LanguageSwitcher,
    AboutSection,
    ContactSection,
    ClientsSection,
    StackSection,
    VideoSection,
};
