<script lang="ts">
  export let variant: "variant1" | "variant2";
  export let heading: string;
  export let caption: string;
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .section--variant1 {
    background-color: var(--background-color1);
  }
  .section--variant2 {
    background-color: var(--background-color2);
  }

  h2 {
    margin: 48px;
    color: var(--text-primary);
  }

  p {
    margin: 0 16px;
    max-width: 600px;
    text-align: center;
    font-size: 20px;
    color: var(--text-primary);
  }
</style>

<svelte:options tag="section-container" />

<section class={`section--${variant}`}>
  <h2>{heading}</h2>

  <p>{caption}</p>

  <slot />
</section>
