<script lang="ts">
  enum FORM_SUBMISSION_STATUS {
    isSending,
    success,
    error,
  }
  let formSubmissionStatus: FORM_SUBMISSION_STATUS;

  enum LANGUAGE {
    english = "English",
    norwegian = "Norwegian",
    japanese = "Japanese",
    other = "Other",
  }
  let language: LANGUAGE = LANGUAGE.english;
  let name: string;
  let email: string;
  let message: string;

  const errors: {
    language?: string | null;
    name?: string | null;
    email?: string | null;
    message?: string | null;
  } = {};

  async function postData(data) {
    const url = "https://api.emailjs.com/api/v1.0/email/send";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response;
  }

  const validateName = () => {
    if (!name || !name.trim()) return (errors.name = "Please write your name");
    errors.name = null;
  };

  const validateEmail = () => {
    if (!email)
      return (errors.email = "Please write an email you can be reached at");

    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegEx.test(email.toLowerCase()))
      return (errors.email = "Please use a valid email");

    errors.email = null;
  };
  const validateMessage = () => {
    if (!message)
      return (errors.message = "Please write the reasons for contacting");
    errors.message = null;
  };
  const validate = () => {
    if (!language) errors.language = "Please select a language";
    validateName();
    validateEmail();
    validateMessage();
  };

  const onSubmit = (event: Event) => {
    event.preventDefault();

    validate();

    const hasErrors =
      Object.values(errors).filter((err) => typeof err === "string").length > 0;
    if (hasErrors) return;

    const data = {
      service_id: "jt_contact_form",
      template_id: "jt_contact_form_template",
      user_id: "user_G3jhr71SJpdotad0t3oBY",
      template_params: {
        reply_language: language,
        from_name: name,
        reply_to: email,
        message: message,
      },
    };

    formSubmissionStatus = FORM_SUBMISSION_STATUS.isSending;

    postData(data)
      .then((response) => {
        formSubmissionStatus = FORM_SUBMISSION_STATUS.success;
      })
      .catch((error) => {
        formSubmissionStatus = FORM_SUBMISSION_STATUS.error;
      });
  };
</script>

<style>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color2);
    color: hsla(0, 0%, 100%, 0.7);
    padding-bottom: 6vw; /* Height of .diagonal */
    min-height: calc(100vh - 6vw); /* Ensures nav link can be active */
  }
  h2 {
    margin: 48px;
  }
  .subtitle {
    margin: 0 16px;
    max-width: 600px;
    text-align: center;
    font-size: 20px;
  }
  .subtitle a {
    text-decoration: none;
    color: hsla(0, 0%, 100%, 0.7);
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.7);
    transition: all 0.4s;
    opacity: 0.7;
    filter: blur(0);
  }
  .subtitle a:hover {
    opacity: 1;
    filter: blur(3px);
  }

  .notice {
    margin: 48px;
    max-width: calc(600px - 32px);
    padding: 8px 16px;
    background-color: hsla(0, 0%, 100%, 0.05);
  }

  /* TODO: separate out */
  .diagonal {
    position: relative;
    width: 100%;
    pointer-events: none;
  }
  .diagonal > svg {
    position: absolute;
    bottom: -10px;
  }
  .diagonal > svg > path {
    fill: hsla(0, 0%, 15%, 1);
  }

  /* RESET */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  input::-moz-focus-inner,
  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  select,
  input,
  textarea,
  button {
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: inherit;
  }
  /* END RESET */

  form {
    width: calc(100% - 32px);
    max-width: 600px;
    display: flex;
    flex-direction: column;
    color: hsla(0, 0%, 100%, 0.7);
    padding: 0 16px;
  }

  option {
    color: hsla(0, 0%, 0%, 0.7);
  }

  label {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 100;
  }

  input,
  textarea {
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 4px;
    border-top: 1px solid hsla(0, 0%, 0%, 0.4);
    border-right: 1px solid hsla(0, 0%, 100%, 0.2);
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    border-left: 1px solid hsla(0, 0%, 0%, 0.4);
    background-color: hsla(0, 0%, 0%, 0.05);
  }

  select,
  button {
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 4px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.2);
    border-right: 1px solid hsla(0, 0%, 0%, 0.4);
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.4);
    border-left: 1px solid hsla(0, 0%, 100%, 0.2);
    background-color: hsla(0, 0%, 100%, 0.05);
    cursor: pointer;
  }

  textarea {
    resize: vertical;
  }

  .specify-language {
    margin: -8px 0 16px;
    text-align: center;
    padding: 8px 16px;
    background-color: hsla(0, 0%, 100%, 0.05);
  }
  .error {
    margin: -8px 0 16px;
    text-align: center;
    padding: 8px 16px;
    font-weight: 600;
    background-color: hsla(0, 0%, 100%, 0.05);
    border: 2px dotted hsla(0, 0%, 0%, 0.5);
    color: hsla(0, 0%, 0%, 0.5);
  }
</style>

<svelte:options tag="contact-section" />

<section>
  <h2>Contact</h2>

  <p class="subtitle">
    To get in touch, please fill out the form below or send an email to
    <a href="mailto:hello@josteinthorsen.com">hello@josteinthorsen.com</a>
  </p>

  <p class="notice">
    Translation software will be used for languages other than English,
    Norwegian, Danish, Swedish and Japanese.
  </p>

  {#if formSubmissionStatus === FORM_SUBMISSION_STATUS.success}
    <span>Successfully sent message</span>
  {:else if formSubmissionStatus === FORM_SUBMISSION_STATUS.error}
    <span>Error sending message</span>
  {:else if formSubmissionStatus === FORM_SUBMISSION_STATUS.isSending}
    <span>Sending message...</span>
  {:else}
    <form on:submit={onSubmit}>
      <label for="language">Preferred reply language</label>
      <select name="language" bind:value={language}>
        {#each Object.values(LANGUAGE) as lang}
          <option value={lang}>{lang}</option>
        {/each}
      </select>
      {#if language === LANGUAGE.other}
        <span class="specify-language">Please specify your preferred language in
          the message</span>
      {/if}

      <label for="name">Name</label>
      <input
        required
        type="text"
        id="name"
        bind:value={name}
        placeholder="Full Name"
        on:change={validateName} />
      {#if errors.name}<span class="error">{errors.name}</span>{/if}

      <label for="email">Email</label>
      <input
        required
        type="email"
        id="email"
        bind:value={email}
        placeholder="email@domain"
        on:change={validateEmail} />
      {#if errors.email}<span class="error">{errors.email}</span>{/if}

      <label for="message">Message</label>
      <!-- TODO: Change placeholder or make multiline on iOS somehow -->
      <textarea
        required
        id="message"
        bind:value={message}
        rows={12}
        placeholder={`Please write the details of the inquiry...`}
        on:change={validateMessage} />
      {#if errors.message}<span class="error">{errors.message}</span>{/if}

      <button type="submit">Send</button>
    </form>
  {/if}
</section>

<div class="diagonal">
  <svg viewBox="0 0 100 100">
    <path d="M 100 100 L 100 98 L 0 94 L 0 98 L 0 100" />
  </svg>
</div>
