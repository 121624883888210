<script lang="ts">
  import stack from "../stack";
  const sortedStack = stack.sort(() => 0.5 - Math.random()); // TODO: Make more random, weighted or consistent sorting

  let frosted = true;
  // const isMobile = window.matchMedia("only screen and (max-width: 760px)")
  //   .matches;

  // < 1472 -> 10 per row
  // < 1360 -> 9 per row
  // < 1248 -> 8 per row
  // < 1136 -> 7 per row
  // < 1024 -> 6 per row
  // < 912 -> 5 per row
  // < 800 -> 4 per row
  // < 760 -> 6 per row
  // < 616 -> 5 per row
  // < 512 -> 4 per row
  // < 408 -> 3 per row
  // < 304 -> 2 per row
  // < 200 -> 1 per row
  const perRow =
    window.innerWidth < 760
      ? Math.ceil((window.innerWidth - 96) / 104)
      : Math.ceil((window.innerWidth - 352) / 112);

  const perPage = 5 * Math.min(perRow, 10);
  const getFilteredStack = (maxShown: number) => sortedStack.slice(0, maxShown);

  let maxShown = perPage;
  let filteredStack = getFilteredStack(maxShown);

  let showMoreButton: HTMLButtonElement;
  const showMore = (event: MouseEvent) => {
    showMoreButton.blur();

    maxShown += perPage;
    filteredStack = getFilteredStack(maxShown);
  };
</script>

<style>
  section {
    width: 100%;
    padding: 0 0 calc(48px + 3vw) 0;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    background-image: linear-gradient(
        hsla(0, 0%, 100%, 0.4),
        hsla(0, 0%, 100%, 0.4)
      ),
      url("assets/images/bricks.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
  }

  .header {
    padding-top: 3vw; /* Half height of .diagonal */
    width: 100%;
    height: 160px;
    background-color: hsla(0, 0%, 100%, 0.2);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header > .content {
    margin: 0 16px;
    max-width: 600px;
    color: var(--text-secondary);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  @media (max-width: 300px) {
    .header {
      height: 200px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    width: calc(100% - (128px * 2));
    max-width: 1200px;
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, 96px);
    gap: 16px 16px;
    justify-content: center;
  }
  li {
    width: 96px;
    height: 96px;
    background-color: hsla(0, 0%, 100%, 0.4);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    border-radius: 4px;
    transition: backdrop-filter 0.4s;
  }
  li:hover,
  li:active {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }
  .frosted {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
  button {
    z-index: 10;
    height: 32px;
    width: 108px;
    text-align: center;
    color: var(--text-secondary);
    font-weight: 600;
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0;
    background: hsla(0, 0%, 100%, 0.4);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.3);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: backdrop-filter 0.4s;
  }
  button:focus,
  button:hover {
    outline: none;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
  button > .content {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .highlight {
    position: absolute;
    width: 96px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }
  .highlight > svg > path {
    fill: hsla(0, 0%, 100%, 0.2);
  }

  .skill svg {
    height: 64px;
    width: 64px;
    padding: 16px;
    z-index: 4;
    transition: filter 0.4s;
  }
  .skill svg path {
    fill: var(--text-secondary);
    fill-rule: evenodd;
  }
  .skill .skill-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 10;
    cursor: default;

    height: 80px;
    width: 80px;
    padding: 8px;
    border-radius: 4px;

    background-color: hsla(0, 0%, 100%, 0.4);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    word-break: break-word;

    opacity: 0;
    transition: opacity 0.7s;
  }

  .skill:focus {
    outline: none;
  }
  .skill:hover .skill-title,
  .skill:focus .skill-title,
  .skill:active .skill-title {
    opacity: 1;
  }
  .skill:hover svg,
  .skill:focus svg,
  .skill:active svg {
    filter: blur(5px);
  }

  @media (max-width: 760px) {
    section {
      padding: 0 0 48px 0;
      width: 100%;
    }
    ul {
      width: 100%;
      margin: 8px auto;
      gap: 8px 8px;
    }
  }

  /* TODO: add alternate styles for browsers without backdrop-filter support */
  /* @supports ((-webkit-backdrop-filter: blur(3px)) or (backdrop-filter: blur(3px))) {
  } */

  /* TODO: separate out */
  .diagonal {
    position: relative;
    width: 100%;
    z-index: 1;
    pointer-events: none;
  }
  .diagonal > svg {
    position: absolute;
  }
  .diagonal--top > svg {
    top: -10px;
  }
  .diagonal--bottom > svg {
    bottom: -10px;
  }
  .diagonal--top > svg > path {
    fill: var(--background-color1);
  }
  .diagonal--bottom > svg > path {
    fill: var(--background-color2);
  }
</style>

<svelte:options tag="stack-section" />

<div class="diagonal diagonal--top">
  <svg viewBox="0 0 100 100">
    <path d="M 0 0 L 0 2 L 100 6 L 100 2 L 100 0" />
  </svg>
</div>

<section>
  <div class={frosted ? 'frosted header' : 'header'}>
    <div class="content">
      Here is a non-exhaustive list over various software and technologies
      Jostein has used or is using in his work:
    </div>
  </div>
  <!-- TODO: Add filter / search ? -->

  <!-- TODO: Fix flicker on large screens (reduce amount of columns?) -->

  <ul>
    {#each filteredStack as skill, index}
      <li>
        <div class="highlight">
          <svg viewBox="0 0 100 100">
            <path d="M 0 0 L 100 4 L 100 0" />
          </svg>
        </div>

        <div class="skill" tabIndex={index + 1}>
          <span class="skill-title">{skill.title}</span>

          <svg viewBox="0 0 128 128">
            <g>
              {#each skill.paths as path}
                <path d={path} />
              {/each}
            </g>
          </svg>
        </div>
      </li>
    {/each}
  </ul>

  {#if maxShown < stack.length}
    <button on:click={showMore} bind:this={showMoreButton}>
      <span class="content">Show More</span>
    </button>
  {/if}
</section>

<div class="diagonal diagonal--bottom">
  <svg viewBox="0 0 100 100">
    <path d="M 100 100 L 100 94 L 0 98 L 0 94 L 0 100" />
  </svg>
</div>
