<script lang="ts">
  import { debounce } from "./helpers";

  let current = window.location.hash.split("#")[1];
  const handleScroll = () => {
    const idsToCheck = ["home", "clients", "about", "contact"];
    const elements = idsToCheck.map((id) => document.getElementById(id));

    const idsInOrBelowView = elements
      .map((element) =>
        element.getBoundingClientRect().top <= 50 ? element.id : null
      )
      .filter((id) => id && id);

    const activeId = idsInOrBelowView[idsInOrBelowView.length - 1];
    current = activeId;

    const activeHash = `#${activeId}`;
    if (history.pushState) {
      history.pushState(null, null, activeHash);

      window.dispatchEvent(
        new HashChangeEvent("hashchange", { newURL: activeHash })
      );
    }
  };

  const debouncedScrollHandler = debounce(handleScroll, 150);
</script>

<style>
  nav {
    position: fixed;
    z-index: 50;
    top: 0;
    right: 0;
    overflow: hidden;
    padding: var(--logo-size) 16px 0;
    height: calc(100vh - var(--logo-size));
    background-color: hsla(0, 0%, 100%, 0.1);
    box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  ul {
    list-style: none;
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  li {
    transition: transform 0.5s;
  }
  li:hover {
    transform: translate(-10px);
  }
  a {
    text-decoration: none;
    font-weight: 300;
  }
  a.active {
    text-decoration: line-through var(--text-primary);
  }
  .link-text {
    display: block;
    padding: 50% 0;
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    text-align: center;
    color: var(--text-primary);
  }
  .divider {
    height: 1px;
    background-color: var(--text-primary);
    opacity: 0.3;
  }
  svg {
    display: none;
  }

  @media (max-height: 470px) {
    nav {
      display: none;
    }
  }
  @media (max-width: 760px) {
    nav {
      top: unset;
      bottom: 0;
      left: 0;
      padding: 0;
      height: unset;
    }
    ul {
      width: 100vw;
      flex-direction: row;
      background-color: var(--background-color-nav);
    }
    li {
      flex: 1;
    }
    li:hover {
      transform: unset;
    }
    .link-text {
      padding: 0;
      transform: unset;
    }
    a {
      width: 100%;
      font-size: 12px;
      font-weight: 600;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 0;
      color: var(--text-disabled);
    }
    a.active {
      text-decoration: none;
      font-weight: 900;
      color: var(--text-primary);
    }
    svg {
      display: inline-block;
      height: 24px;
      margin-bottom: 4px;
    }
    svg > path,
    svg > circle {
      fill: var(--text-disabled);
    }
    a.active > svg > path,
    a.active > svg > circle {
      fill: var(--text-primary);
    }
  }
</style>

<svelte:options tag="nav-menu" />
<svelte:window
  on:scroll={debouncedScrollHandler}
  on:resize={debouncedScrollHandler} />

<nav>
  <ul>
    <li>
      <a href="#home" class:active={current === 'home'}>
        <svg viewBox="0 0 24 24">
          <path
            d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
        </svg>
        <span class="link-text">Home</span>
      </a>
    </li>

    <span class="divider" />

    <li>
      <a href="#clients" class:active={current === 'clients'}>
        <svg viewBox="0 0 24 24">
          <path
            d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h4v-3 C23,14.82,19.43,13.53,16.67,13.13z" />
          <circle cx="9" cy="8" r="4" />
          <path
            d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24 C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76C14.09,11.9,14.53,12,15,12z" />
          <path d="M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z" />
        </svg>
        <span class="link-text">Clients</span>
      </a>
    </li>

    <span class="divider" />

    <li>
      <a href="#about" class:active={current === 'about'}>
        <svg viewBox="0 0 24 24">
          <path
            d="M9 11.75c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zm6 0c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37C11.07 8.33 14.05 10 17.42 10c.78 0 1.53-.09 2.25-.26.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z" />
        </svg>

        <span class="link-text">About</span>
      </a>
    </li>

    <!-- <span class="divider" />

    <li>
      <a href="#stack" class:active={current === 'stack'}>
        <svg viewBox="0 0 24 24"><path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"/></svg>
        <span class="link-text">Stack</span>
      </a>
    </li> -->

    <span class="divider" />

    <li>
      <a href="#contact" class:active={current === 'contact'}>
        <svg viewBox="0 0 24 24"><path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" /></svg>
        <span class="link-text">Contact</span>
      </a>
    </li>
  </ul>
</nav>
