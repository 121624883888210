<script lang="ts">
  import CubeFace from "./cube-face.svelte";

  const isMobile = window.innerWidth < 600;

  type Face = "top" | "left" | "front" | "right" | "bottom" | "back";
  const faces: Record<Face, { x: number; y: number }> = {
    top: { x: -90, y: 0 },
    left: { x: 0, y: 90 },
    front: { x: 0, y: 0 },
    right: { x: 0, y: -90 },
    bottom: { x: 90, y: 0 },
    back: { x: 0, y: -180 },
  };

  let pos = { x: 0, y: 0 };
  const handleMousemove = (event: MouseEvent) => {
    if (isMobile) return;

    const w = window.innerWidth;
    const h = window.innerHeight;

    pos.x = event.clientX / window.innerWidth;
    pos.y = event.clientY / window.innerHeight;
  };

  const getFaceFromHash = (hash) => {
    switch (hash) {
      case "home":
        return "top";
      case "clients":
        return "left";
      case "about":
        return "bottom";
      case "contact":
        return "right";

      default:
        return "top";
    }
  };

  let currentFace: Face = getFaceFromHash(window.location.hash.split("#")[1]);
  const handleHashChange = (event: HashChangeEvent) => {
    const currentHash = event.newURL.split("#")[1];

    currentFace = getFaceFromHash(currentHash);
  };

  const getRotationX = (rotation, posy) =>
    isMobile ? rotation.x : rotation.x - posy * 20 + 10;
  const getRotationY = (rotation, posx) =>
    isMobile ? rotation.y : rotation.y + posx * 20 - 10;
  const translateZ = -200;
</script>

<style>
  /* TODO: Fix on Desktop */
  .scene {
    position: fixed;
    z-index: 100;
    right: 0;
    width: var(--logo-size);
    height: var(--logo-size);
    perspective: 200px;
  }

  .cube {
    width: var(--logo-size);
    height: var(--logo-size);
    transform-style: preserve-3d;
    transition: transform 1s;
  }

  .mobile-background {
    display: none;
  }
  @media (max-width: 760px) {
    .scene {
      right: calc(50vw - var(--logo-size-half));
    }
    .mobile-background {
      display: inline-block;
      left: 0;
      top: 0;
      position: fixed;
      z-index: 5;
      width: 100vw;
      height: calc(var(--logo-size) * 0.6);
      background-color: hsla(0, 0%, 100%, 0.2);
      box-shadow: 1px 1px 5px 0 hsla(0, 0%, 0%, 0.1);
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
    }
  }
</style>

<svelte:options tag="logo-cube" />
<svelte:body on:mousemove={handleMousemove} />
<svelte:window on:hashchange={handleHashChange} />

{#if isMobile}
  <span class="mobile-background" />
  <div class="scene">
    <div
      class="cube"
      style="transform: translateZ({translateZ}px) rotateX({getRotationX(faces[currentFace], pos.y)}deg) rotateY({getRotationY(faces[currentFace], pos.x)}deg);">
      <a href="#home">
        <cube-face direction="top" content="_JOSTEIN_" />
        <cube-face direction="left" content={'_CLIENTS_'} />
        <cube-face direction="front" content={'  _  __  '} />
        <cube-face direction="right" content={'_CONTACT_'} />
        <cube-face direction="bottom" content={'__ABOUT__'} />
        <cube-face direction="back" content={' _ __ _  '} />
      </a>
    </div>
  </div>
{/if}
