<script lang="ts">
  const supportedLanguages = ["en", "jp"];
</script>

<style>
  ul {
    position: fixed;
    bottom: 0;
    right: 0;
    list-style: none;
    display: inline-flex;
    padding: 0 8px;
    margin: 24px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid black;
    border-radius: 4px;
  }
  li {
    width: 20px;
    padding: 8px;
    font-weight: 300;
    cursor: pointer;
  }
  /* TODO: style selected */
  li:nth-child(1) {
    font-weight: 600;
    cursor: default;
  }
  li:hover {
    font-weight: 600;
  }
</style>

<svelte:options tag="language-switcher" />

<ul>
  {#each supportedLanguages as language}
    <li>{language}</li>
  {/each}
</ul>
